/* Application-wide styles */
.app-container {
  padding: 20px;
  padding-bottom: 60px;
  position: relative;
  min-height: 100vh;
}

header {
  display: flex;
  justify-content: space-between;
}

.header-icon {
  padding-right: 7px;
}

.header-admin {
  height: 36px;
}

.auth-logout {
  height: 36px;
}

.auth-container {
  display: flex;
  flex-direction: column;
}

.auth-buttons {
  padding: 10px 0;
}

.login-input {
  width: 30vw;
  padding: 5px 0;
}

.packages-header {
  display: flex;
  justify-content: space-between;
}

.packages-checkin-button {
  height: 36px;
}

.filterbox {
  width: 30vw;
}

/* Check in popup styles */
.checkin-container {
  padding: 3%;
}

.checkin-form {
  padding: 2%;
}

.checkin-resident {
  width: 300px;
}

.checkin-tracking {
  width: 300px;
}

.checkin-close {
  float: right;
}

.admin-delete-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-csv {
  width: 40vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fixed-height-loader {
  height: 10vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.footer-content {
  text-align: center;
}
